import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
        //#自动adminPath#
{path: adminPath + "/orderExchangeApply", component: () => import("./orderExchangeApply_index.vue")},
        {path: adminPath + "/orderAuditRecord", component: () => import("./orderAuditRecord_index.vue")},
        {path: adminPath + "/orderServeItemAdditional", component: () => import("./orderServeItemAdditional_index.vue")},
        {path: adminPath + "/orderRefund", component: () => import("./orderRefund_index.vue")},
        {path: adminPath + "/orderServeSend", component: () => import("./orderServeSend_index.vue")},
        {path: adminPath + "/orderServe", component: () => import("./orderServe_index.vue")},
        {path: adminPath + "/orderInfo", component: () => import("./orderInfo_index.vue")},
        {path: adminPath + "/orderServeItem", component: () => import("./orderServeItem_index.vue")},
        {path: adminPath + "/orderServeBack", component: () => import("./orderServeBack_index.vue")},
        {path: adminPath + "/orderServeEval", component: () => import("./orderServeEval_index.vue")},
        {path: adminPath + "/orderServeEvalNxuser", component: () => import("./orderServeEvalNxuser_index.vue")},
        {path: adminPath + "/oldOrderInfo", component: () => import("./oldOrderInfo_index.vue")},
        {path: adminPath + "/orderServeItemStatement", component: () => import("./orderServeItemStatement_index.vue")},
        {path: adminPath + "/sellStatistical", component: () => import("./sellStatistical_index.vue")},
        {path: adminPath + "/firmAreaStatistical", component: () => import("./firmAreaStatistical_index.vue")},
        {path: adminPath + "/orderServePlan", component: () => import("./orderServePlan_index.vue")},
        {path: adminPath + "/orderServeVisitStatement", component: () => import("./orderServeVisitStatement_index.vue")},
    ],
    pages: []
}
